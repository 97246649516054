export const getWrapperStyles = (mediaFirst: boolean, mediaFirstDesktop: boolean, isInsetLayout: boolean) => ({
	minHeight: { xs: 'auto', md: '30rem' },
	overflow: 'hidden',
	margin: '0 auto',
	display: 'flex',
	flexDirection: {
		xs: mediaFirst ? 'column' : 'column-reverse',
		md: mediaFirstDesktop ? 'row' : 'row-reverse',
	},
	gap: isInsetLayout ? '0 2rem' : '0',
	paddingLeft: { lg: isInsetLayout ? '5rem' : 0, md: isInsetLayout ? '2.5rem' : 0 },
	paddingRight: { lg: isInsetLayout ? '5rem' : 0 },
	alignItems: {
		xs: 'center',
		md: 'stretch',
	},
	'> .MuiGrid-item': {
		paddingTop: 0,
		paddingLeft: 0,
	},
});

export const disclaimerWrapperStyles = {
	marginTop: '.5rem',
};

export const contentStyles = (isInsetLayout: boolean) => ({
	maxWidth: {
		sm: '100%',
		md: isInsetLayout ? '60%' : '50%',
	},
});

export const mediaStyles = (isInsetLayout: boolean, forceHideContentHalf: boolean) => ({
	maxWidth: {
		sm: '100%',
		md: isInsetLayout ? '40%' : '50%',
	},
	...(forceHideContentHalf && { maxWidth: '100%' }),
});
