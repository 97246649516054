import { useMediaQuery, useTheme } from '@mui/material';

import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';
import { ContentfulBackground, Maybe, TAGContentAlignment, TemplateWidth } from '@/types';
import {
	HalfScreen as THalfScreen,
	ContentTypeRichText,
	HalfScreenBody,
	Asset,
	DisclaimerV2,
	HalfScreenButtonCollection,
	HalfScreenHeading,
	SmallScreenContent,
	TermsAndConditions,
	Video,
	ImageAsset,
	VideoAsset,
	HalfScreenSubHeading,
} from '@/types/generated';
import { textToJumpLink } from '@/utils/textToJumpLink/textToJumpLink';

import Disclaimer from '../Disclaimer';
import TemplateContainer from '../TemplateContainer/TemplateContainer';

import ContentHalf from './ContentHalf';
import { disclaimerWrapperStyles, getWrapperStyles, contentStyles, mediaStyles } from './HalfScreen.styles';
import MediaHalf from './MediaHalf/MediaHalf';

export interface IHalfScreen extends THalfScreen {
	/**
	 * Required field for the HalfScreen id
	 *
	 * @type {string}
	 * @memberof IHalfScreen
	 */
	name?: Maybe<string>;
	/**
	 * The background color of the template.
	 *
	 * @type {Maybe<ContentfulBackground>}
	 * @memberof IService
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * The rich text template body.
	 *
	 * @type {Maybe<ServiceRichTextDescription>}
	 * @memberof IService
	 */
	body?: Maybe<HalfScreenBody>;
	/**
	 * The collection of buttons to display. Maximum of 2 buttons.
	 *
	 * @type {Maybe<ServiceButtonsCollection>}
	 * @memberof IService
	 */
	buttonCollection?: Maybe<HalfScreenButtonCollection>;
	/**
	 * Contains small screen overrides for the template.
	 *
	 * @type {Maybe<SmallScreenContent>}
	 * @memberof IHalfScreen
	 */
	contentSmallScreen?: Maybe<SmallScreenContent>;
	/**
	 * The disclaimer template.
	 *
	 * @type {Maybe<DisclaimerV2>}
	 * @memberof IHalfScreen
	 */
	disclaimer?: Maybe<DisclaimerV2>;
	/**
	 * The plain text eyebrow for the template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 */
	eyebrow?: Maybe<string>;
	/**
	 * The eyebrow style for the eyebrow. Can be Standard or Promo.
	 *
	 * @type {Maybe<TAGEyebrowStyle>}
	 * @memberof IHalfScreen
	 */
	eyebrowStyle?: Maybe<string>;
	/**
	 * The rich text title for the template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 */
	heading?: Maybe<HalfScreenHeading>;
	/**
	 * The rich text sub title for the template.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	subHeading?: Maybe<HalfScreenSubHeading>;
	/**
	 * The image to display. The reference to an image asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	image?: Maybe<Asset>;
	/**
	 * Specifies whether the media should be displayed first or not.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IHalfScreen
	 */
	mediaFirst?: Maybe<boolean>;
	/**
	 * The size of the template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof IHalfScreen
	 */
	templateSize?: Maybe<string>;
	/**
	 * The terms and conditions template.
	 *
	 * @type {Maybe<TermsAndConditions>}
	 * @memberof IHalfScreen
	 */
	termsAndConditions?: Maybe<TermsAndConditions>;
	/**
	 * The video to display.
	 *
	 * @type {Maybe<Video>}
	 * @memberof IHalfScreen
	 */
	video?: Maybe<Video>;
	/**
	 * Brandfolder Image with focal point.
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof IHalfScreen
	 */
	imageAsset?: Maybe<ImageAsset>;
	/**
	 * Video Asset with video for Desktop and Mobile
	 *
	 * @type {Maybe<VideoAsset>}
	 * @memberof IHalfScreen
	 */
	videoAsset?: Maybe<VideoAsset>;
	/**
	 * Brandfolder Image with focal point. This enables the Before/After functionality
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof IHalfScreen
	 */
	imageAssetTwo?: Maybe<ImageAsset>;
	/**
	 * Specifies whether the content half will be displayed on mobile or not.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IHalfScreen
	 */
	hideContentHalf?: Maybe<boolean>;
	/**
	 * Specifies whether the content half will be displayed. Used in case like display results from Dentrino
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IHalfScreen
	 */
	forceHideContentHalf?: boolean;
	/**
	 * Enables the appearance of one side being larger and extra x-padding (desktop only)
	 *
	 * @type {Maybe<string>}
	 * @memberof IHalfScreen
	 */
	isInsetLayout?: Maybe<boolean>;
	/**
	 * Enables the appearance of one side being larger and extra x-padding (desktop only)
	 *
	 * @type {Maybe<string>}
	 * @memberof IHalfScreen
	 */
	useStaticImageSize?: Maybe<boolean>;
	/**
	 * Used to identify a component on a page for the purpose of jumping to it using the anchor link.
	 *
	 * @type {Maybe<string>}
	 * @memberof IHalfScreen
	 */
	anchorId?: Maybe<string>;
	/**
	 * Used to align text within the component.
	 *
	 * @type {Maybe<TAGContentAlignment>}
	 * @memberof IHalfScreen
	 */
	textAlignment?: Maybe<TAGContentAlignment>;
}

export default function HalfScreen({
	name,
	backgroundColor,
	body,
	buttonCollection,
	contentSmallScreen,
	termsAndConditions,
	heading,
	subHeading,
	eyebrow,
	eyebrowStyle,
	image,
	templateSize,
	video,
	disclaimer,
	mediaFirst,
	imageAsset,
	videoAsset,
	imageAssetTwo,
	hideContentHalf = false,
	forceHideContentHalf = false,
	isInsetLayout = false,
	useStaticImageSize = false,
	anchorId,
	textAlignment,
}: Readonly<IHalfScreen>) {
	const _templateSize = templateSize ?? 'Full';
	const _mediaFirst = mediaFirst ?? true;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');

	const shouldDisplayMediaFirstMobile = contentSmallScreen?.mediaFirst ?? _mediaFirst;

	const templateBackground = resolveContentfulBgColor(backgroundColor as ContentfulBackground, theme);

	const idLabel = textToJumpLink(name);

	return (
		<>
			<TemplateContainer
				disableGutters
				dataTestId="section_half_screen"
				{...(anchorId && { id: textToJumpLink(anchorId) })}
				contentfulBackgroundColor={templateBackground}
				templateWidth={(templateSize as TemplateWidth) ?? 'Full'}
				containerSx={getWrapperStyles(shouldDisplayMediaFirstMobile, _mediaFirst, !!isInsetLayout)}
			>
				<MediaHalf
					image={image}
					imageSmallScreen={contentSmallScreen?.imageSmallScreen}
					isSmallScreen={isSmallScreen}
					video={video}
					id={`hs-media-${idLabel}`}
					imageAsset={imageAsset}
					videoAsset={videoAsset}
					imageAssetTwo={imageAssetTwo}
					containerSx={mediaStyles(!!isInsetLayout, forceHideContentHalf)}
					useStaticImageSize={useStaticImageSize}
				/>
				{(hideContentHalf && isSmallScreen) || forceHideContentHalf ? null : (
					<ContentHalf
						body={body}
						buttons={buttonCollection?.items}
						eyebrow={eyebrow}
						eyebrowStyle={eyebrowStyle}
						heading={heading}
						subHeading={subHeading}
						isDarkBackground={isDarkBackground}
						isSmallScreen={isSmallScreen}
						termsAndConditions={termsAndConditions}
						id={`hs-content-${idLabel}`}
						theme={theme}
						containerSx={contentStyles(!!isInsetLayout)}
						textAlign={textAlignment}
					/>
				)}
			</TemplateContainer>

			{disclaimer && (
				<Disclaimer
					disclaimer={disclaimer.disclaimer as NonNullable<ContentTypeRichText>}
					dataTestId="half_screen_disclaimer"
					wrapperSx={disclaimerWrapperStyles}
				/>
			)}
		</>
	);
}
