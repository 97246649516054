import { Box, Theme, Typography, SxProps } from '@mui/material';

import { Maybe, TAGContentAlignment } from '@/types';
import {
	HalfScreenButtonCollection,
	HalfScreenBody,
	HalfScreenHeading,
	ContentTypeRichTextContent,
	SmallScreenContent,
	TermsAndConditions as TTermsAndConditions,
	HalfScreenSubHeading,
} from '@/types/generated';
import TermsAndConditions from '@/components/TermsAndConditions';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import ContentfulButton from '@/components/ContentfulButton';

import { wrapper, getBodyTextStyles, textContainerStyles, halfScreenButtonWrapperStyles } from './ContentHalf.styles';
import { resolveEyebrowTextColor } from './ContentHalf.helpers';

interface IContentHalf {
	buttons?: HalfScreenButtonCollection['items'];
	eyebrow?: Maybe<string>;
	eyebrowStyle?: Maybe<string>;
	heading?: Maybe<HalfScreenHeading>;
	subHeading?: Maybe<HalfScreenSubHeading>;
	body?: Maybe<HalfScreenBody>;
	termsAndConditions?: Maybe<TTermsAndConditions>;
	isSmallScreen: boolean;
	isDarkBackground: boolean;
	contentSmallScreen?: Maybe<SmallScreenContent>;
	id: string;
	theme?: Theme;
	containerSx?: SxProps;
	textAlign?: Maybe<TAGContentAlignment>;
}

export default function ContentHalf({
	termsAndConditions,
	eyebrow,
	eyebrowStyle,
	heading,
	subHeading,
	body,
	buttons,
	contentSmallScreen,
	isSmallScreen,
	isDarkBackground,
	id,
	theme,
	containerSx = {},
	textAlign = 'Center',
}: Readonly<IContentHalf>) {
	const headingContent =
		isSmallScreen && contentSmallScreen?.headingSmallScreen ? contentSmallScreen.headingSmallScreen : heading;
	const bodyContent =
		isSmallScreen && contentSmallScreen?.bodySmallScreen ? contentSmallScreen.bodySmallScreen : body;
	const wrapperSx: object = { ...wrapper(textAlign), ...containerSx };
	const textAlignment = (textAlign?.toLowerCase() ? textAlign.toLowerCase() : 'center') as
		| 'left'
		| 'right'
		| 'center';
	return (
		<Box data-test-id="section_content_half_screen" id={id} sx={wrapperSx}>
			<Box sx={textContainerStyles(textAlign)}>
				{eyebrow && (
					<Typography
						align={textAlignment}
						data-test-id="group_half_screen_eyebrow"
						variant={eyebrowStyle === 'Promo' ? 'bodyLargeBold' : 'bodyMediumBook'}
						color={resolveEyebrowTextColor({
							isDarkBackground,
							eyebrowStyle,
						})}
					>
						{eyebrow}
					</Typography>
				)}

				{headingContent && (
					<ContentTypeRichText
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						backgroundColor="transparent"
						dataTestId="group_half_screen_heading"
						centerAlign={textAlign === 'Center'}
						content={headingContent as ContentTypeRichTextContent}
						docProps={{
							margin: 0,
							color: isDarkBackground ? 'text.light' : 'text.primary',
							variant: 'header1',
						}}
					/>
				)}

				{subHeading && (
					<ContentTypeRichText
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						backgroundColor="transparent"
						dataTestId="group_half_screen_subheading"
						centerAlign={textAlign === 'Center'}
						content={subHeading as ContentTypeRichTextContent}
						docProps={{
							margin: '0 0 0.5rem',
							color: 'accent02.main',
							variant: 'header3',
						}}
					/>
				)}

				{bodyContent && (
					<ContentTypeRichText
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						dataTestId="group_half_screen_body"
						backgroundColor="transparent"
						centerAlign={textAlign === 'Center'}
						content={bodyContent as ContentTypeRichTextContent}
						docProps={{
							sx: getBodyTextStyles(isDarkBackground, theme),
						}}
					/>
				)}

				{buttons && buttons.length > 0 && (
					<Box data-test-id="group_half_screen_buttons_wrapper" sx={halfScreenButtonWrapperStyles(textAlign)}>
						{buttons.map((button) =>
							button ? (
								<ContentfulButton key={button.sys.id} {...button} dataTestId="button_half_screen_cta" />
							) : null
						)}
					</Box>
				)}

				{termsAndConditions && (
					<Box data-test-id="group_half_screen_terms_and_conditions_wrapper">
						<TermsAndConditions
							{...termsAndConditions}
							textColor={isDarkBackground ? 'text.light' : 'text.secondary'}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
}
