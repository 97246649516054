import { Theme } from '@mui/material';

import { Maybe, TAGContentAlignment } from '@/types';

export const wrapper = (textAlign: Maybe<TAGContentAlignment>): object => ({
	display: 'flex',
	textAlign: textAlign?.toLowerCase() || 'center',
	justifyContent: textAlign === 'Center' ? 'center' : 'flex-start',
	alignItems: 'center',
	width: '100%',
	padding: {
		xs: '2rem 1.25rem',
		md: '0',
	},
});

export const textContainerStyles = (textAlign: Maybe<TAGContentAlignment> = 'Center'): object => ({
	display: 'flex',
	margin: 'auto',
	width: '100%',
	maxWidth: '80%',
	alignSelf: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: textAlign === 'Center' ? 'center' : 'flex-start',
	gap: '1rem',
});

export const halfScreenButtonWrapperStyles = (textAlign: Maybe<TAGContentAlignment> = 'Center'): object => ({
	display: 'flex',
	gap: '0.75rem',
	flexWrap: 'wrap',
	justifyContent: textAlign?.toLowerCase() || 'center',
	alignItems: 'center',
	paddingY: '1rem',
});

export const getBodyTextStyles = (darkMode: boolean, theme?: Theme) =>
	darkMode
		? {
				color: 'text.light',
				...(theme && { textDecorationColor: darkMode ? theme.palette.text.light : null }),
		  }
		: {};
